@supports (background: -webkit-named-image(i)) {
  .safari-height {
    @apply h-[21vh] sm:h-[80vh] md:h-[70vh] lg:h-[60vh] xl:h-[50vh];
  }
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&family=Open+Sans:wght@300;400;800&display=swap");
@font-face {
  font-family: "tw_cen_mt_stdsemi_medium";
  src: url("./fonts/twcenmtstd-semimedium-webfont.woff2") format("woff2"),
    url("./fonts/twcenmtstd-semimedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "product_sansbold";
  src: url("./fonts/fontsfree-net-productsans-bold-webfont.woff2")
      format("woff2"),
    url("./fonts/fontsfree-net-productsans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold";
  src: url("./fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("./fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratbold";
  src: url("./fonts/montserrat-bold_allfont.net-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-bold_allfont.net-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratmedium";
  src: url("./fonts/fontsfree-net-montserrat-medium-webfont.woff2")
      format("woff2"),
    url("./fonts/fontsfree-net-montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "levenim_mtbold";
  src: url("./fonts/lvnmbd_1-webfont.woff2") format("woff2"),
    url("./fonts/lvnmbd_1-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "open_sanssemibold_italic";
  src: url("./fonts/fontsfree-net-opensans-semibolditalic-webfont.woff2")
      format("woff2"),
    url("./fonts/fontsfree-net-opensans-semibolditalic-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratsemibold_italic";
  src: url("./fonts/montserrat-semibolditalic-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "viner_hand_itcregular";
  src: url("./fonts/vineritc-webfont.woff2") format("woff2"),
    url("./fonts/vineritc-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.newsList > a {
  color: #000;
}

video {
  width: 100%;
}
#mobile-menu {
  display: none;
}
.mob-menu {
  display: none;
}
.aie {
  font-family: "viner_hand_itcregular";
  font-size: larger;
}
.main_header {
  /*position: fixed;*/
  position: relative;
  width: 100%;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  /* z-index: 99; */
  background: #fff; /*top: 26px;*/
}
.main_header .navbar-brand img {
  width: 90%;
}
.main_header #basic-navbar-nav .navbar-nav a {
  color: #fff;
  font-family: "tw_cen_mt_stdsemi_medium";
  font-size: 22px;
}
.main_header #basic-navbar-nav .navbar-nav li:nth-last-child(1) a {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background: #202437;
  color: #fff;
  padding: 10px 25px;
  border-radius: 35px;
}
.main_header #basic-navbar-nav .navbar-nav a {
  color: #1d2031;
  padding-right: 30px;
}
.home-banner {
  position: relative;
  padding-top: 5vh;
  height: auto;
  padding-bottom: 5vh;
  background: url("/public/images/banner_bg.webp"),
    linear-gradient(66deg, #576a995c 0%, rgb(247 247 236) 59%);
}
.home-banner .container {
  max-width: 90%;
}
.banner-information h1 {
  margin: 0;
  color: #fff;
  font-size: 52px;
  font-family: "product_sansbold";
  margin-bottom: 0.3em;
}
.banner-information h3 {
  margin-top: 0.8em;
  color: #071946;
  font-family: "open_sanssemibold_italic";
  font-size: 26px;
  margin-bottom: 0.3em;
}
.banner-information p {
  margin-top: 0.8em;
  color: #071946;
  font-family: "open_sanssemibold_italic";
  font-size: 17px;
  margin-bottom: 0.3em;
}
.button-banner {
  display: block;
  margin-top: 3em;
}
.button-banner a:nth-child(1) {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  border: 2px solid #96e279;
  border-radius: 35px;
  background: #96e279;
  color: #21263b;
  padding: 17px 15px;
  font-family: "robotobold";
  text-decoration: none;
  margin-right: 1.6em;
  font-size: 17px;
  padding-right: 25px;
}
.button-banner a:nth-child(1) > i {
  background: #fff;
  padding: 8px;
  color: #000;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  font-weight: 500;
  text-align: center;
  margin-right: 0.5em;
}
.button-banner a:nth-child(2) {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  border-radius: 35px;
  color: #96e279;
  padding: 17px 45px;
  font-family: "robotobold";
  text-decoration: none;
  margin-right: 4em;
  font-size: 17px;
  border: 2px solid #96e279;
}
.button-banner a:hover:nth-child(1) {
  background: none;
  color: #96e279;
}
.button-banner a:hover:nth-child(2) {
  background: #96e279;
  color: #212539;
}
.main_header #basic-navbar-nav .navbar-nav li:nth-last-child(1) a:hover {
  background: #36a8de;
  color: #fff;
}
.precision-inf h3 {
  font-size: 24px;
  font-family: "montserratbold";
  color: #1ba1dd;
}
.precision-inf h2 {
  font-size: 48px;
  color: #000;
  font-family: "montserratbold";
}
.precision-inf h4 {
  font-size: 24px;
  font-family: "montserratmedium";
  color: #000;
  margin-bottom: 0.5em;
}
.precision-inf p {
  font-size: 19px;
  line-height: 36px;
}
.precision-monitoring {
  padding: 80px 0 80px 0;
}
.we-deliver .deliver-heading h2 {
  font-family: "montserratbold";
  color: #fff;
}
.we-deliver {
  padding: 100px 0 100px 0;
  position: relative;
  z-index: 1;
  background: #252c42;
}
.we-deliver .deliver-heading p {
  color: #fff;
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
}
.we-deliver .deliver-heading {
  text-align: center;
}
.we-deliver:before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
  content: "";
  z-index: -1;
  background: #252c42;
}
.solution-tabs {
  margin-top: 2em;
}
.solution-tabs .nav-pills .nav-link.active {
  background: #5e83fd;
  border-radius: 10px;
}
.solution-tabs .nav-item h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 19px;
  color: #fff;
  margin-bottom: 0.5em;
}
.solution-tabs .nav-item p {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}
.solution-tabs .nav-pills .nav-item {
  margin: 0 0 1em 0;
  padding: 0px;
  cursor: pointer;
}
.solution-tabs .nav-pills .nav-link {
  padding: 25px;
  position: relative;
}
.solution-tabs .nav-pills .nav-item {
  position: relative;
}
.solution-tabs .nav-pills .nav-item:nth-child(1):before {
  position: absolute;
  content: url("/public/images/sensors.webp");
  left: -15px;
  top: 19px;
  z-index: 1;
}
.solution-tabs .nav-pills .nav-item:nth-child(2):before {
  position: absolute;
  content: url("/public/images/hardware.webp");
  left: -15px;
  top: 19px;
  z-index: 1;
}
.solution-tabs .nav-pills .nav-item:nth-child(3):before {
  position: absolute;
  content: url("/public/images/secured.webp");
  left: -15px;
  top: 19px;
  z-index: 1;
}
.solution-tabs .nav-pills .nav-item:nth-child(4):before {
  position: absolute;
  content: url("/public/images/hybrid.webp");
  left: -15px;
  top: 19px;
  z-index: 1;
}
.solution-tabs .tab-content {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background-image: linear-gradient(
    to right top,
    #272d44,
    #30334b,
    #393852,
    #423e59,
    #4c4460
  );
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  line-height: 29px;
  font-size: 19px;
}
.deploy-faster h1 {
  text-align: center;
  font-family: "montserratbold";
  font-size: 34px;
  line-height: 54px;
}
.deploy-faster {
  position: relative;
  padding-bottom: 80px;
  background: #f2f6fa;
  padding-top: 80px;
}
.box-info {
  margin-top: 1em;
}
.box-info ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box-info ul li {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background: #fff;
  position: relative;
  width: 32%;
  border-radius: 7px;
  padding: 25px;
  margin-bottom: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.box-info ul li img {
  margin-bottom: 1em;
}
.box-info ul li:nth-child(even):hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}
.box-info ul li:nth-child(odd):hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.box-info ul li:nth-child(even) {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background: #fff;
  position: relative;
  width: 32%;
  box-shadow: 0px 20px 90px #9caab3;
  border-radius: 7px;
  padding: 25px;
  margin-bottom: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background: linear-gradient(0deg, rgb(233 233 233) 11%, rgb(255 255 255) 86%);
  text-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.15s ease, -webkit-transform 0.15s ease;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
}
.box-info ul li h2 {
  margin-top: 80px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.6em;
}
.box-info ul li p {
  color: #657197;
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: left;
}
.box-info ul li i {
  text-align: left;
  font-size: 22px;
  color: #252c42;
}
.box-info ul li:nth-child(odd) {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-transition: box-shadow 0.15s ease, -webkit-transform 0.15s ease;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: linear-gradient(0deg, rgb(24 51 141) 11%, rgb(50 96 179) 86%);
  background: radial-gradient(100% 100% at 100% 0%, #5adaff 0%, #5468ff 100%);
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%),
    inset 0px -3px 0px rgb(58 65 111 / 50%);
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
}
.box-info ul li:nth-child(odd) p {
  color: #fff;
}
.box-info ul li:nth-child(odd) a {
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: 8px;
}
.box-info ul li:nth-child(even) p {
  color: #5b409d;
}
.box-info ul li:nth-child(even) a {
  color: #5b409d;
  text-decoration: none;
  position: absolute;
  bottom: 8px;
}
.box-info ul li:nth-child(4),
.box-info ul li:nth-child(5),
.box-info ul li:nth-child(6) {
  margin-bottom: 0;
}
.main_header.is-sticky {
  background: #fff;
  z-index: 3;
  top: 26px;
  box-shadow: 0 0 20px #272e46;
}
.solution-tabs .tab-content a {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background: #1b9bd9;
  padding: 14px 35px;
  color: #fff;
  text-decoration: none;
  font-family: "robotobold";
  border-radius: 35px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
}
.explore-deploy a {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background: #1b9bd9;
  padding: 12px 35px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  border-radius: 35px;
}
.explore-deploy a:hover {
  color: #fff;
  background: #252c42;
}
.solution-tabs .tab-content a:hover {
  color: #fff;
  background: #423e59;
}
.academy-heading h2 {
  font-family: "montserratbold";
  color: #fff;
  font-size: 40px;
}
.academy-heading p {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #fff;
}
.academy-works {
  padding: 0px 0 100px 0;
  position: relative;
  z-index: 1;
  background: #252c42;
}
.academy-works:before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
  content: "";
  z-index: -1;
  background: #252c42;
}
.tab-horizontal .flex-column {
  flex-direction: row !important;
  justify-content: center;
}
.tab-horizontal,
#gap-work {
  margin-top: 5em !important;
}
#gap-work {
  margin-top: 2em !important;
}
.tab-horizontal .flex-column .nav-item {
  background: #515668;
  border-radius: 35px;
}
.tab-horizontal .flex-column .nav-item a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  padding: 15px 35px;
  border-radius: 35px;
  cursor: pointer;
}
.tab-horizontal .flex-column .nav-item a.active {
  color: #2b376a;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
}
.tab-horizontal .flex-column .back-wrap {
  background: #515668;
  display: flex;
  border-radius: 35px;
}
.monitored-heading h4 {
  color: #0073e6;
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.monitored-heading h2 {
  color: #0073e6;
  font-size: 36px;
  font-family: "tw_cen_mt_stdsemi_medium";
  font-weight: 800;
  color: #000;
}
.monitored-ipumpnet {
  background: #f2f6fa;
  padding: 85px 0 0px 0 !important;
  position: relative;
  z-index: 1;
}
.monitored-ipumpnets {
  background: url("/public/images/big-cubes.webp") #f2f6fa;
  padding-top: 70px;
  position: relative;
  z-index: 1;
  background-position: bottom;
}
.monitored-points ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.monitored-points ul li {
  width: 46%;
  margin-bottom: 3.2em;
}
.monitored-points ul li .content-right h4 {
  font-family: "levenim_mtbold";
  font-size: 22px;
  color: #273266;
  margin-bottom: 0.5em;
}
.monitored-points ul li .content-right p {
  color: #003e51;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0.5em;
}
.monitored-points ul li .wrap-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.monitored-points ul li .wrap-list .image-left {
  width: 20%;
}
.monitored-points ul li .wrap-list .content-right {
  width: 80%;
}
.monitored-points ul li .wrap-list .image-left img {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 19px 39px #cdd8e9;
}
.monitored-points ul li .wrap-list .content-right a {
  text-decoration: none;
  color: #3b4c99;
  font-family: "levenim_mtbold";
  font-size: 18px;
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
}
.monitored-points ul li .wrap-list .content-right a i {
  margin-left: 8px;
}
.trusted-by-heading h4 {
  color: #5275ee;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.trusted-by-heading h2 {
  color: #fff;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.trusted-by {
  position: relative;
  z-index: 1;
  padding: 180px 0;
  background-image: linear-gradient(180deg, #f2f6fa, #f2f6fa);
}
.trusted-by:before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
  content: "";
  z-index: -1;
  background: #133095;
}
.trusted-by .container {
  position: relative;
}
.trusted-by .container:before {
  position: absolute;
  content: url("/public/images/trusted-bg.webp");
  left: -20%;
  top: 19px;
  z-index: 1;
}
.trusted-by .container::after {
  position: absolute;
  content: url("/public/images/trusted-right-bg.webp");
  right: -20%;
  top: 19px;
  z-index: 1;
}
.carousel-trusted .carousel-caption {
  position: unset;
  width: 100%;
  margin: 0 auto;
}
.carousel-trusted .customer-message {
  border-radius: 32px;
  display: flex;
  background: #fff;
}
.carousel-trusted .customer-message .left-message {
  width: 60%;
  background: #193fc2;
  border-bottom-right-radius: 80px;
  text-align: left;
  padding: 45px;
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
}
.carousel-trusted .customer-message .right-message {
  width: 40%;
  text-align: left;
  padding: 45px;
  position: relative;
}
.carousel-trusted .customer-message .left-message p {
  color: #fff;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-size: 400;
  line-height: 32px;
}
.carousel-trusted .customer-message .left-message img {
  margin-bottom: 1.6em;
}
.carousel-trusted .customer-message .left-message .bottom-position p {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 400;
}
.carousel-trusted .customer-message .left-message .bottom-position span {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 700;
  color: #96aeff;
}
.carousel-trusted .customer-message .right-message h2 {
  color: #193fc2;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-size: 80px;
}
.carousel-trusted .customer-message .right-message p {
  font-size: 24px;
  color: #193fc2;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 32px;
}
.carousel-trusted .customer-message .left-message .bottom-position {
  margin-top: 4em;
}
.carousel-trusted .customer-message .right-message a {
  position: absolute;
  bottom: 3em;
  right: 3.5em;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}
.carousel-trusted .carousel-control-next,
.carousel-trusted .carousel-control-prev {
  position: absolute;
  z-index: 1;
  display: flex;
  top: auto;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 7px;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
  bottom: -50px;
  background: #fff;
  border-radius: 25px;
}
.carousel-trusted .carousel-control-prev {
  right: 50px;
  left: auto;
}
.article-heading h4 {
  color: #0073e6;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.article-heading h2 {
  color: #000;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.articles {
  background: #f2f6fa;
  padding: 60px 0;
  position: relative;
  z-index: 1;
}
.article-data ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.article-data ul li {
  width: 30%;
  padding: 240px 20px 15px 20px;
  border-radius: 10px;
}
.article-data ul li:nth-child(1) {
  background: url("/public/images/article-bg.webp") no-repeat right top #434954;
}
.article-data ul li:nth-child(2) {
  background: url("/public/images/article-bg.webp") no-repeat right top #323242;
}
.article-data ul li:nth-child(3) {
  background: url("/public/images/article-bg.webp") no-repeat right top #0073e6;
}
.article-data ul li span {
  font-size: 16px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 0.8em;
  display: inline-block;
}
.article-data ul li p {
  font-size: 18px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-bottom: 0.8em;
}
.article-data ul li .bottom-date span {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
}
.article-data ul li .bottom-date a {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  background: #fff;
  text-decoration: none;
  padding: 3px 24px;
  border-radius: 25px;
}
.article-data ul li .bottom-date {
  display: flex;
  justify-content: space-between;
}
.main-footer {
  background: url("/public/images/footer-bg.webp") no-repeat top 80px center
    #1c0732;
  padding-top: 70px;
}
.getting h2 {
  color: #fff;
  font-size: 40px;
  font-family: "robotobold";
  font-weight: 500;
}
.getting p {
  font-size: 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #a89cb4;
}
.getting .get-demo a:nth-child(1) {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  margin-right: 19px;
  border: 2px solid #96e279;
  color: #96e279;
  border-radius: 7px;
  padding: 15px 42px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 600;
  font-weight: 600;
  font-size: 16px;
}
.getting .get-demo a:hover:nth-child(1) {
  background: #96e279;
  color: #1c0732;
}
.getting .get-demo a:nth-child(2) {
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  border: 2px solid #96e279;
  background: #96e279;
  color: #1c0732;
  border-radius: 7px;
  padding: 15px 42px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 600;
  font-weight: 600;
  font-size: 16px;
}
.getting .get-demo a:hover:nth-child(2) {
  background: none;
  color: #fff;
}
.footer-left .footer-mail ul li a {
  color: #fff;
  font-family: "tw_cen_mt_stdsemi_medium";
  font-size: 19px;
  text-decoration: none;
}
.footer-left .footer-mail ul li {
  display: inline-block;
  color: #fff;
  font-size: 19px;
}
.footer-left .footer-mail ul {
  list-style: none;
  padding: 0;
}
.footer-left .footer-mail ul li:nth-child(1) {
  margin-right: 45px;
}
.footer-right ul {
  list-style: none;
  padding: 0;
  float: right;
  margin-bottom: 30px;
}
.footer-right ul li {
  display: inline;
  margin: 0 8px;
}
.footer-right ul li a {
  font-size: 18px;
  font-family: "montserratmedium";
  color: #fff;
  text-decoration: none;
}
.main-footer .container {
  max-width: 1366px;
}
.main-footer .container #bottom_footer {
  margin-top: 250px;
  border-bottom: 2px solid #fff;
  padding-bottom: 15px;
}
.footer-email .email-control {
  min-width: 350px;
  background: #352446;
  padding: 10px 15px;
  color: #b2acb9;
  border: none;
  border-radius: 8px;
}
.footer-email .email-control:focus-visible {
  border: none;
  outline: none;
}
.footer-email {
  float: right;
  text-align: right;
}
.footer-email button {
  margin: 0;
  background: #0073e6;
  padding: 12px 15px;
  color: #fff;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  border: none;
  margin-left: 5px;
}
.footer-email .email-control::placeholder {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
.footer-email p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #a294b1;
  font-size: 14px;
  margin-top: 12px;
}
.floating-form {
  float: right;
}
.designed {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
}
.designed p {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin-right: 15px;
  margin-bottom: 0;
  color: #fff;
}
.copyright-bottom {
  padding: 25px 0;
}
.terma-condition ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.terma-condition ul li a {
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
}
.terma-condition ul li {
  display: inline-block;
}
.terma-condition ul li:nth-child(1) {
  margin-right: 15px;
  border-right: 1px solid #fff;
  padding-right: 15px;
}
.terma-condition ul li:nth-child(2) {
  margin-right: 15px;
  border-right: 1px solid #fff;
  padding-right: 15px;
}
.copy p {
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 0;
}
.copy {
  margin-top: 15px;
}
.top-to-btm {
  position: fixed;
  bottom: 10%;
  z-index: 50;
  transform: rotate(270deg);
  border: none !important;
  right: -28px;
  font-size: 18px;
}
.top-to-btm i {
  transform: rotate(90deg);
}
.top-to-btm button {
  border: 0;
  background: radial-gradient(
    100% 100% at 100% 0%,
    rgb(90, 218, 255) 0%,
    rgb(84, 104, 255) 100%
  );
  color: #fff;
  padding: 8px 21px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  box-shadow: rgb(45 35 66 / 40%) 0px 2px 4px,
    rgb(45 35 66 / 30%) 0px 7px 13px -3px,
    rgb(58 65 111 / 50%) 0px -3px 0px inset;
}
.top-to-btm button:hover {
  background: #576a99 !important;
  box-shadow: 0 0 10px #576a99;
}
.top-mail ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.top-mail ul li {
  font-family: "tw_cen_mt_stdsemi_medium";
  display: inline;
}
.top-mail ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}
.top-bar {
  background: #576a99;
  padding: 0px 0;
}
.top-bar.is-sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}
.top-mail ul li:nth-child(1) {
  margin-right: 45px;
}
.top-mail ul li i {
  color: #fff;
  font-size: 13px;
}
.top-right ul li a {
  font-family: "tw_cen_mt_stdsemi_medium";
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
}
.top-right ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.top-right ul li {
  display: inline;
  margin: 0 18px;
}
.top-right {
  text-align: right;
}
.logo-carousel {
  background: #1e2437d6;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.logo-carousel h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  color: #fff;
  line-height: 32px;
  font-weight: 300;
}
.item h4 {
  color: #fff;
}
.logos ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.logos ul li {
  display: inline;
  margin: 0 20px;
}
.arrow-img img {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  margin-top: 10px;
}
.navbar-nav a {
  text-decoration: none;
}
.pagetitle {
  background: url("/public/images/about_banner.webp") no-repeat right top
    #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.pagetitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.pagetitle > .container {
  position: relative;
}
.pagetitle h1 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}
.breadcrumb-page ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb-page ul li {
  display: inline;
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
}
.breadcrumb-page ul li a {
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
}
.breadcrumb-page {
  text-align: right;
}
.precision-monitoring.about {
  padding-bottom: 100px;
}
.why-choose {
  padding: 100px 0;
  position: relative;
  z-index: 1;
  background: #3d476a;
}
.heading h2 {
  font-family: "montserratbold";
  color: #fff;
}
.heading p {
  color: #fff;
  font-size: 19px;
  font-family: "Open Sans", sans-serif;
}
.pumpnet-description {
  margin: 0;
  padding: 80px 0;
}
.phara-info p {
  text-align: justify;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 30px;
}
.phara-info ul {
  padding: 0;
  list-style: none;
  margin-top: 18px;
  margin-bottom: 0;
}
.phara-info ul li {
  list-style: none;
  margin-bottom: 15px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 19px;
  background: url("/public/images/check.webp") no-repeat left top 5px;
  padding-left: 35px;
}
.benefits {
  background: #f7f7f7;
  padding: 70px 0;
}
.benefits ul {
  margin: 0;
  padding: 0;
}
.benefits ul li {
  color: #fff;
}
.benefits h2 {
  color: #252c42;
  font-size: 32px;
  font-family: "montserratbold";
}
.benefits ul li:hover:before {
  top: -10px !important;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
}
.benefits ul li {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #00b5e2;
  margin-bottom: 7px;
  position: relative;
}
.benefits ul li:nth-child(1):before {
  box-shadow: 10px 10px 35px #a9a8cb;
  content: "1";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #7d79e8;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.benefits ul li:nth-child(2):before {
  box-shadow: 10px 10px 35px #69b5bb;
  content: "2";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #4dbec8;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.benefits ul li:nth-child(3):before {
  box-shadow: 10px 10px 35px #a5b3e5;
  content: "3";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #7293fe;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.benefits ul li:nth-child(4):before {
  box-shadow: 10px 10px 35px #7dbacf;
  content: "4";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #1eb7ee;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.benefits ul li:nth-child(5):before {
  box-shadow: 10px 10px 35px #e1c99d;
  content: "5";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #feb94e;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.benefits ul li:nth-child(6):before {
  box-shadow: 10px 10px 35px #bfc9d3;
  content: "6";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #a4bace;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.benefits ul li:nth-child(7):before {
  box-shadow: 10px 10px 35px #727ea7b5;
  content: "7";
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
  position: absolute;
  color: #fff;
  background: #727ea7;
  font-family: "robotobold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}

.float-right {
  float: right;
}

.contacttitle {
  background: url("/public/images/contact-banner.webp") no-repeat right top
    #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.contacttitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.contacttitle > .container {
  position: relative;
}

.abouttitle {
  background: url("/public/images/about_us.webp") no-repeat right top #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.abouttitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.abouttitle > .container {
  position: relative;
}

.mediatitle {
  background: url("/public/images/media.webp") no-repeat right top #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.mediatitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.mediatitle > .container {
  position: relative;
}

.presstitle {
  background: url("/public/images/press.webp") no-repeat right top;
  padding: 450px 0 0px 0;
  position: relative;
}
.presstitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.presstitle > .container {
  position: relative;
}

.careertitle {
  background: url("/public/images/career_bg.webp") no-repeat right top #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.careertitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.careertitle > .container {
  position: relative;
}
.careerphara p {
  font-size: 18px;
  line-height: 26px;
}
.annualtitle {
  background: url("/public/images/Annual.png") no-repeat right top #434954;
  padding: 400px 0 0 0;
  position: relative;
  background-position: center;
  background-size: cover;
}
.downloadtitle {
  background: url("/public/images/download.png") no-repeat right top #434954;
  padding: 400px 0 0 0;
  position: relative;
  background-position: center;
  background-size: cover;
}
.downloadtitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.downloadtitle > .container {
  position: relative;
}

/* .projectstitle { background: url('/public/images/projects_banner.webp') no-repeat right top #434954; padding: 450px 0 0px 0;  position: relative;} */
.projectstitle {
  background-image: url("/public/images/projects_banner.webp");
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
}
.projectstitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.projectstitle > .container {
  position: relative;
}

#tblProjects table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}
#tblProjects table.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ddd !important;
}

#tblProjects table.table th,
#tblProjects table.table td {
  border: 1px solid #ddd !important;
  text-align: center;
}

.page-tit h1 {
  color: #fff;
  font-family: "tw_cen_mt_stdsemi_medium";
}
.contact-page {
  padding: 100px 0;
  background: #f3f3f3;
}
.left-contact h3 {
  font-family: "montserratbold";
}
.left-contact p {
  font-family: "tw_cen_mt_stdsemi_medium";
  font-size: 23px;
  line-height: 25px;
  color: #fff;
}
.address h3 {
  font-size: 18px;
  font-family: "robotobold";
}
.address p,
.address a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.contact-form {
  background: #fff;
  padding: 25px;
}
.contact-form h4 {
  font-weight: 800;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  color: #485c9d;
  margin-bottom: 30px;
}
.contact-form input {
  width: 100%;
  border: 1px solid #979797;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 5%);
  padding: 10px 13px;
  font-weight: 300;
  border: 1px solid #979797;
  font-size: 1.1rem;
  color: #6a6a6a;
  box-shadow: 0px 8px 11px #0000001f;
  border-radius: 5px;
  border: 1px solid #000;
}
.contact-form textarea {
  box-shadow: 0px 8px 11px #0000001f;
  border-radius: 5px;
  border: 1px solid #000;
  width: 100%;
  height: 200px;
  border: 1px solid #dee0e3;
  padding: 12px;
  border: 1px solid #979797;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 5%);
  padding: 10px 13px;
  font-weight: 300;
  border: 1px solid #979797;
  font-size: 1.1rem;
  color: #6a6a6a;
}
.contact-form .btnContact {
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 50%;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  box-shadow: 0.2rem 0.8rem 1.6rem 0.2rem rgb(226 233 255);
  padding: 12px;
  border: none;
  background: #4f66ad;
  color: #fff;
  font-size: 18px;
  border-radius: 7px;
  font-family: "robotobold";
}
.contact-form .btnContact:hover {
  background: #252c42;
  box-shadow: 0.2rem 0.8rem 1.6rem 0.2rem #252c4230;
}
.contact-page .container > .row {
  box-shadow: 0 0 30px #ccc;
}
.contact-page .container {
  background: linear-gradient(0deg, #252c42 0%, #404c72 100%);
}
.address ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.address ul li img {
  width: 40%;
  box-shadow: 0px 13px 40px 0 #252c42;
  padding: 20px;
  background: #fff;
}
.address ul li {
  width: 40%;
  display: inline-block;
  text-align: center;
}
.address ul li h3 {
  font-size: 22px;
  font-family: "robotobold";
  margin-top: 20px;
  color: #fff;
}
.address ul li a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 5px;
  display: inline-block;
}
.whatsapp h3 {
  font-size: 19px;
  margin-top: 30px;
  color: #fff;
}
.whatsapp {
  position: relative;
  padding-left: 65px;
  border-top: 1px solid #b5b5b5;
  padding-top: 0;
  margin-top: 25px;
  color: #fff;
}
.whatsapp img {
  position: absolute;
  left: 0;
  top: 14px;
}
.contact-form label {
  font-family: "open_sanssemibold_italic";
}
.teamtitle {
  background: url("/public/images/team_banner.webp") no-repeat right top #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.teamtitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.teamtitle > .container {
  position: relative;
}

.credentialstitle {
  background: url("/public/images/credentials.webp") no-repeat right top #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.credentialstitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.credentialstitle > .container {
  position: relative;
}

.knowledgetitle {
  background: url("/public/images/knowledge-banner.webp") no-repeat right top
    #434954;
  padding: 450px 0 0px 0;
  position: relative;
  background-size: cover;
  background-position-x: center;
}
.knowledgetitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.knowledgetitle > .container {
  position: relative;
}

.productsolution {
  background: url("/public/images/productsolution.webp") no-repeat right top
    #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.productsolution:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.productsolution > .container {
  position: relative;
}
.team-client {
  padding: 30px 0 40px 0;
}
.heading-title h2 {
  font-family: "montserratbold";
}
.team-grid {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.team-item-click-area {
  padding: 10px;
}

.team-item-click-area-main {
  padding: 10px;
}
.team-item-main {
}

.team-item img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.team-item-main img {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.team-item-photo {
  /* width: 100%; */
  border-radius: 40px;
}
.team-item-brief {
  position: relative;
}
.team-item-brief p {
  padding-bottom: 0px;
}
.team-item-brief p.team-item-title {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #ccc;
  padding: 5px 12px;
  border-radius: 25px;
  display: inline;
}
.team-item-arrow {
  position: absolute;
  top: 16px;
  right: 0;
}
.team-item.is--active .team-item-arrow {
  -webkit-transform: rotateZ(-180deg);
  transform: rotateZ(-180deg);
}
.team-item-main.is--active .team-item-arrow {
  -webkit-transform: rotateZ(-180deg);
  transform: rotateZ(-180deg);
}
.team-item-arrow .et-pb-icon {
  font-size: 46px;
  font-weight: 100;
}
.team-item.is--active .team-item-brief-arrow {
  opacity: 1;
}
.team-item-main.is--active .team-item-brief-arrow {
  opacity: 1;
}
.team-item.is--active .team-item-description {
  display: block;
}
.team-item-main.is--active .team-item-description-main {
  display: block;
}
.team-item-description {
  background: #434c6a;
  color: #fff;
  position: relative;
  display: none;
  width: 95%;
  left: 0;
  padding: 1.2em 3.5em 1em 2em;
  box-shadow: 0px 16px 20px #a0a0a09e;
  margin: 2em 0px 3em 0;
}
.team-item-description-main {
  background: #434c6a;
  color: #fff;
  position: relative;
  display: none;
  width: 95%;
  left: 0;
  padding: 1.2em 3.5em 1em 2em;
  box-shadow: 0px 16px 20px #a0a0a09e;
  margin: 2em 0px 3em 0;
}

.team-item-description-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
}
.team-item-description-close .et-pb-icon {
  font-size: 37px;
  font-weight: 100;
}
.team-item-description-close-main {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
}
.team-item-description-close-main .et-pb-icon {
  font-size: 37px;
  font-weight: 100;
}

.et_pb_animation_top.et-animated {
  opacity: 1;
  -webkit-animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  animation: fadeTop 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
}
.team-item:hover img,
.is--active img {
  box-shadow: 0px 11px 25px #4c4c4c6b;
  filter: none !important;
}
.team-item-main:hover img,
.is--active img {
  box-shadow: 0px 11px 25px #4c4c4c6b;
  filter: none !important;
}
.team-item-arrow i {
  font-size: 32px;
}
.team-item-name {
  font-family: "Montserrat", sans-serif;
}
.team-item-description-close i {
  font-size: 36px;
  cursor: pointer;
}
.team-item-description-close-main i {
  font-size: 36px;
  cursor: pointer;
}
.team-item-description p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
}
.team-item-description strong {
  font-family: "montserratbold";
  margin-bottom: 10px;
  display: inline-block;
}
.team-item-description ul li {
  list-style-type: disclosure-closed;
}
.team-item-description-main strong {
  font-family: "montserratbold";
  margin-bottom: 10px;
  display: inline-block;
}
.team-item-description-main ul li {
  list-style-type: disclosure-closed;
}
.breadcrumb-page ul li:nth-child(2):before {
  content: "";
  background: #fff;
  width: 1px;
  height: 100%;
  transform: rotate(20deg);
  display: inline-block;
  top: 0;
  position: absolute;
  left: -10px;
}
.breadcrumb-page ul li:nth-child(3):before {
  content: "";
  background: #fff;
  width: 1px;
  height: 100%;
  transform: rotate(20deg);
  display: inline-block;
  top: 0;
  position: absolute;
  left: -10px;
}
.breadcrumb-page ul li {
  position: relative;
}
.benefits ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.benefits ul li {
  background: #fff;
  box-shadow: 0 0 33px #0000000a;
  padding: 20px;
  text-align: center;
  color: #000;
  list-style: none;
  width: 23%;
  margin: 1% 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 11px;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
}
.benefits ul li span {
  background: #534f99;
  color: #fff;
  padding: 3px 12px;
  margin-top: 2px;
  display: inline-block;
  border-radius: 7px;
}
.benefits ul li p {
  margin-top: 20px;
  font-size: 17px;
  font-family: "open_sanssemibold_italic";
  font-weight: 400;
}
/*.benefits { background: url('/public/images/pattern.svg') ;}*/
.table-design .background-dark {
  background: #576a99;
  color: #fff;
  font-size: 22px;
}
.table-design .table .thead-light th {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: #252c42;
}
.table-design .background-dark {
  font-family: "tw_cen_mt_stdsemi_medium";
}
.table-design td span {
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px;
}
.table-design .thead-light th {
  font-family: "montserratbold";
}
.table-design .thead-light td i {
  color: #afc0f7;
  font-weight: 300;
}
.table-design td {
  vertical-align: middle;
}
.table-design .card {
  border: none;
}
.table-design .card tbody th {
  font-weight: 400;
  vertical-align: middle;
  width: 50px;
}
.table-design .card tbody tr td:nth-child(2) {
  width: 250px;
}
.table-design .card tbody tr:hover {
  background-color: #eef3ff;
}
.table-design .background-dark:hover {
  background: #576a99 !important;
}
.table-design .card tbody th:nth-child(1) {
  text-align: center;
}
.table-design {
  margin: 80px 0;
}
.about-products {
  padding-top: 70px;
  padding-bottom: 70px;
}
.productimg img {
  border-radius: 15px;
}
.boost-text p {
  font-size: 30px;
  font-family: "open_sanssemibold_italic";
}
.about-products {
  position: relative;
}
.about-products:before {
  background: #eff5fb;
  clip-path: polygon(
    50% 0,
    100% 0,
    100% 35%,
    100% 85%,
    32% 100%,
    0 87%,
    0% 35%,
    0 0
  );
  content: "";
  width: 100%;
  top: 0;
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.arrow-box {
  background: #fff;
  width: 90px;
  height: 90px;
  position: relative;
  border-radius: 85px;
  box-shadow: 0px 20px 60px #cccccc4d;
}
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}
.arrow {
  width: 40px;
  height: 40px;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #181717 solid;
  border-bottom: 2px #181717 solid;
}
.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #181717 solid;
  border-bottom: 1px #181717 solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}
.technologytitle {
  margin: 0;
  background: url("/public/images/technology.webp");
  padding: 450px 0 0px 0;
  position: relative;
}
.termstitle {
  margin: 0;
  background: url("/public/images/terms_banner.webp");
  padding: 450px 0 0px 0;
  position: relative;
}
.privacytitle {
  margin: 0;
  background: url("/public/images/privacy_policy.webp");
  padding: 450px 0 0px 0;
  position: relative;
}
.technologytitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.technologytitle > .container {
  position: relative;
}
.iiot-based {
  margin: 0;
}
.iiot-based h2 {
  font-family: "robotobold";
  font-size: 30px;
}
.iiot-based p {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 30px;
}
.iiot-manage {
  padding: 90px 0;
}
.iiot-manage img {
  border-radius: 7px;
  box-shadow: 0 0 30px #ccc;
}
.components h2 {
  font-family: "robotobold";
  font-size: 30px;
}
.components p {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 30px;
}
.list-component ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.list-component ul li {
  position: relative;
  padding: 15px;
  border: 1px solid #e7e7e7;
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 0px 20px #ccc;
  background: #fff;
}
.list-component ul li h4 {
  position: relative;
  color: #000;
}
.components {
  background: url("/public/images/image.jpg") no-repeat;
  background-size: cover;
  padding-top: 90px;
}
.machine-learning img {
  border-radius: 7px;
  box-shadow: 0 0 30px #ccc;
}
.dropdown ul:not(.show) {
  display: none;
}
.dropdown ul.show {
  display: block;
  position: absolute;
  width: 100%;
  background: #272e46;
  width: 320px;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item a {
  cursor: pointer;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown a:nth-child(1) {
  padding-right: 8px !important;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul {
  padding: 10px;
  list-style: none;
  padding-bottom: 20px;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li a {
  user-select: none;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li a {
  display: block;
  padding: 7px 0 !important;
  font-size: 21px;
}
.nav-item.dropdown ul li a {
  color: #fff !important;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li:hover a {
  color: #252c42 !important;
}
.main_header
  #basic-navbar-nav
  .navbar-nav
  .nav-item.dropdown
  ul
  li:nth-last-child(1)
  a {
  background: none;
  color: #fff;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li {
  border-bottom: 1px solid #576a99;
  padding: 0px 0;
}
.requestbanner {
  background: url("/public/images/request.webp") 0 0 no-repeat,
    linear-gradient(65deg, #3a3042 50%, #2e5a6a);
  padding: 40px 0 40px 0;
  position: relative;
}
.leftrequest h2 {
  color: #fff;
  font-family: "robotobold";
  font-size: 30px;
  margin-bottom: 20px;
}
.leftrequest p {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}
.requestform {
  background: #fff;
  padding: 22px;
  max-width: 450px;
  margin: 0 auto;
}
.requestform .formdemo input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
}
#demo .req-quote {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#demo .req-quote .req-field {
  width: 49%;
}
#msg-req {
  margin-bottom: 20px;
}
.requestform .col-md-6 {
  position: relative;
}
.requestform h2 {
  margin-bottom: 18px;
  font-family: "robotobold";
  font-size: 30px;
  text-align: center;
  text-transform: capitalize;
}
.requestform button {
  width: 100%;
  padding: 12px;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
  background: #6d00b9;
  color: #fff;
  font-family: "robotobold";
  border: none;
}
.requestform button:hover {
  background: #3e006b;
}
.sayhlo h2 {
  font-family: "robotobold";
}
.sayhlo h3 {
  margin-top: 0.8em;
  color: #333;
  line-height: 30px;
  font-family: "open_sanssemibold_italic";
  font-size: 22px;
  margin-bottom: 0.3em;
}
.sayhlo p {
  font-family: "Open Sans", sans-serif;
}
.sayhlo {
  text-align: center;
}
.say {
  margin: 80px 0;
}
.lesswork ul {
  margin-top: 50px;
}
.lesswork ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.lesswork ul li {
  text-align: center;
  width: 31%;
  padding: 18px 15px;
  box-shadow: 0 22px 30px #d7e7f780;
}
.lesswork ul li img {
  margin-bottom: 15px;
}
.lesswork ul li h4 {
  font-family: "open_sanssemibold_italic", sans-serif;
}
.lesswork ul li p {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
}
.formdemo input::placeholder {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.say .goschedule a {
  background: #6266e4;
  color: #fff !important;
  padding: 15px 35px;
  font-family: "open_sanssemibold_italic", sans-serif;
  border-radius: 35px;
  font-size: 19px;
  cursor: pointer;
}
.say .goschedule {
  margin-top: 70px;
}
.say .goschedule a:hover {
  background: #052767;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown a:nth-child(2) {
  top: 3px;
  position: relative;
}
.precision-inf a {
  transition: all 0.2s ease, transform 0.6s ease;
  border: 2px solid #1b9bd9;
  border-radius: 35px;
  background: #1b9bd9;
  color: #fff;
  padding: 14px 30px;
  font-family: "robotobold";
  text-decoration: none;
  font-size: 17px;
  padding-right: 25px;
  margin-top: 3px;
  display: inline-block;
}
.precision-inf a:hover {
  background: #252c42;
  border: 2px solid #252c42;
  color: #fff;
}
.we-deliver {
  margin-top: 15px;
}
.precision-inf p {
  font-size: 18px;
  line-height: 34px;
  text-align: justify;
}
@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
.getaquotebt {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 2;
}
.getaquotebt a {
  background: url("/public/images/get-touch.webp") no-repeat 0 0;
  width: 63px;
  height: 54px;
  padding: 2px 0 0 10px;
  display: block;
  z-index: 9999;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer !important;
}
.getaquotebt a:hover {
  width: 172px;
}
.whatsapp a {
  color: #fff;
  text-decoration: none;
}
.whatsapp a:hover {
  color: #fff;
}
.ipumpnetpoint ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.ipumpnetpoint ul li {
  width: 25%;
  text-align: center;
}
.ipumpnetpoint ul li img {
  width: 100%;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
}
.ipumpnetpoint ul li p {
  font-family: "open_sanssemibold_italic";
  font-size: 18px;
  color: #1c0732;
  margin-top: 10px;
}
.ipumpnetsolution {
  position: relative;
  padding: 80px 0;
  background: rgb(255, 255, 255);
  background: url("/public/images/graph-blue-line.svg") no-repeat center
    rgb(245, 245, 250);
  background-size: cover;
}
.ipumpnetsolution h5 {
  font-family: "product_sansbold";
  line-height: 39px;
  font-size: 26px;
  color: #000;
}
.ipumpnetpoint ul li span {
  border: 5px solid #1c0732;
  display: inline-block;
  width: 50%;
  padding: 30px;
  border-radius: 50%;
  background: #1c0732;
}
.ipumpnetpoint ul li:hover img {
  transform: scale(1.2);
}
.pumpperformance h2 {
  font-family: "open_sanssemibold_italic";
  font-size: 25px;
}
.pumpperformance {
  padding: 60px 0;
  background: url("/public/images/application-bg.webp"),
    linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250));
  background-size: cover;
  background-position: center;
}
.applicationtitle {
  background: url("/public/images/application-banner.webp") no-repeat right top
    #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.applicationtitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.applicationtitle > .container {
  position: relative;
}
.parametertitle {
  background: url("/public/images/applicationbanner.webp") no-repeat right top
    #434954;
  padding: 450px 0 0px 0;
  position: relative;
}
.parametertitle:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(20, 61, 88, 0) 60%, #0d1d44 100%);
  z-index: 0;
}
.parametertitle > .container {
  position: relative;
}
.operating-parameters ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.operating-parameters ul li {
  width: 30%;
  list-style: none;
  background: #fff;
  padding: 10px;
  position: relative;
  margin: 2% 1%;
  box-shadow: -10px -7px 64px #b3cdd15c;
}
.operating-parameters ul li .wrap-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.operating-parameters ul li .wrap-list .image-left {
  width: 20%;
  margin-right: 2%;
}
.operating-parameters ul li .wrap-list .image-left img {
  width: 100%;
}
.operating-parameters ul li .wrap-list .content-right {
  width: 78%;
}
.operating-parameters ul li .wrap-list .content-right h4 {
  font-size: 16px;
  margin-bottom: 0;
  font-family: "open_sanssemibold_italic";
}
.operating-parameters ul li:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.operating-parameters ul li:before {
  border-bottom: 2px solid #90abff;
  border-left: 2px solid #90abff;
  -webkit-transform-origin: 0 100%;
}
.operating-parameters ul li:after {
  border-top: 2px solid #90abff;
  border-right: 2px solid #90abff;
  -webkit-transform-origin: 100% 0;
}
.operating-parameters ul li:hover:before {
  border-bottom: 2px solid #90abff;
  border-left: 2px solid #90abff;
  -webkit-transform-origin: 0 100%;
}
.operating-parameters ul li:hover:after {
  border-top: 2px solid #90abff;
  border-right: 2px solid #90abff;
  -webkit-transform-origin: 100% 0;
}
.operating-parameters ul li:hover:after,
.operating-parameters ul li:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.operating-parameters ul li:after,
.operating-parameters ul li:before {
  width: 100%;
  height: 100%;
  z-index: 3;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.points {
  margin: 0;
}
.points ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.points ul li {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}
.trademark {
  padding: 70px 0;
  background: url("/public/images/bg.webp") repeat-x;
}
.points ul li:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f058";
  position: absolute;
  left: 0;
  font-weight: 900;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li:hover {
  background: #fff;
}
.main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li:hover a {
  color: #252c42;
}
.academyhome .academy-works {
  background: #fff;
  padding: 70px 0;
}
.academyhome .academy-works:before {
  clip-path: none;
}
.exclusivity {
  background: #252c42;
  padding: 70px 0;
}
#exclusivity {
  background: #f2f6fa;
  padding-top: 90px;
}
.exclusivity .firstkind h2 {
  font-family: "product_sansbold";
  color: #fff;
}
.exclusivity .firstkind h4 {
  font-family: "product_sansbold";
  color: #fff;
}
.exclusivity .firstkind p {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #1c0732;
  margin-top: 10px;
  color: #fff;
  text-align: justify;
}
.benefits-logo img {
  width: 170px;
}
.box-info img {
  width: 100px;
  background: #fff;
  padding: 15px;
  border-radius: 10%;
}
.box-info ul li:nth-child(even) img {
  margin-bottom: 1em;
  box-shadow: 0 0 20px #ccc;
}
.box-info ul li:nth-child(odd) img {
  box-shadow: 0 0 20px #5468ff;
}
.ipumpnetpoint .tab-content {
  text-align: center;
}
.ipumpnetsolution .ipumpnetpoint .tab-horizontal .flex-column .back-wrap {
  background: #515668;
  display: flex;
  border-radius: 35px;
  flex-direction: column;
  box-shadow: 0 2px 4px rgb(45 35 66 / 35%), 0 7px 13px -3px rgb(45 35 66 / 25%);
}
.ipumpnetsolution .ipumpnetpoint .tab-horizontal .flex-column .nav-item a {
  border-radius: 8px;
}
.ipumpnetsolution .ipumpnetpoint .tab-horizontal .flex-column .nav-item {
  background: #515668;
  border-radius: 8px;
}
.ipumpnetsolution .ipumpnetpoint .tab-horizontal .flex-column .nav-item a {
  font-family: "product_sansbold";
  font-size: 18px;
}
.ipumpnetsolution .ipumpnetpoint .tab-horizontal {
  margin-top: 0 !important;
}
.ipumpnetsolution .ipumpnetpoint .tab-content img {
  background-image: radial-gradient(
    circle at 50% 0px,
    rgb(72, 76, 122),
    rgb(35, 38, 59)
  );
  border-radius: 8px;
}
.list-product {
  margin-top: 70px;
}
.list-product ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.list-product ul li {
  transition: all 0.8s ease;
  flex-wrap: wrap;
  width: 19%;
  text-align: center;
  padding: 25px 0;
  font-family: "product_sansbold";
  font-weight: 800;
  font-size: 19px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
}
.list-product ul li img {
  clear: both;
  width: 40%;
}
.list-product ul li:nth-child(1) img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.list-product ul li:nth-child(2) img {
  -webkit-animation: mover1 2s infinite alternate;
  animation: mover1 1.2s infinite alternate;
}
.list-product ul li:nth-child(3) img {
  -webkit-animation: mover2 3s infinite alternate;
  animation: mover2 1.4s infinite alternate;
}
.list-product ul li:nth-child(4) img {
  -webkit-animation: mover3 3s infinite alternate;
  animation: mover3 1.5s infinite alternate;
}
.list-product ul li:nth-child(5) img {
  -webkit-animation: mover4 4s infinite alternate;
  animation: mover4 1.6s infinite alternate;
}
.list-product ul li:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes mover1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes mover2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes mover3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes mover4 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover4 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
#section-technology img {
  background-image: radial-gradient(
    circle at 50% 0px,
    rgb(72, 76, 122),
    rgb(35, 38, 59)
  );
  border-radius: 8px;
}
#secured img {
  background-image: radial-gradient(
    circle at 50% 0px,
    rgb(72, 76, 122),
    rgb(35, 38, 59)
  );
  border-radius: 8px;
}
#section-technology {
  background: url("/public/images/footer-waves.svg") no-repeat center;
  background-size: cover;
}
.find-application {
  margin: 0;
}
.find-application ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.find-application ul li {
  text-align: center;
  width: 24%;
  background: #fff;
  box-shadow: 0 0 10px #ccc;
  margin-bottom: 1%;
  transition: all 0.2s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
}
.find-application ul li:hover {
  background: #202437;
}
.border-right {
  border-right: 1px solid #dbd6d6;
}
.find-application ul li:hover span {
  color: #fff;
}
.find-application ul li img {
  margin-bottom: 0;
  width: 100%;
}
.find-application ul li span {
  margin: 0;
  padding: 10px 0;
  display: inline-block;
}
.find-application ul li:nth-child(2) {
  float: right;
}
.find-application-heading {
  margin: 80px auto;
  width: 85%;
}
.find-application-heading ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
.find-application-heading ul li {
  margin: 0;
  text-align: center;
}
.find-application-heading ul li:nth-child(1) {
  width: 28%;
}
.find-application-heading ul li img {
  margin-bottom: 10px;
}
.find-application-heading ul li h2 {
  font-size: 58px;
  font-family: "montserratsemibold_italic";
  font-weight: 800;
}
.find-application-third {
  margin: 80px auto;
  width: 68%;
}
.find-application-third ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
.find-application-third ul li {
  margin: 0;
  text-align: center;
}
.find-application-third ul li img {
  margin-bottom: 10px;
}
.find-application-third ul li:nth-child(2) {
  margin-top: -55px;
}
.find-application-four {
  margin: 0px auto;
  width: 40%;
}
.find-application-four ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
.find-application-four ul li {
  margin: 0;
  text-align: center;
}
.find-application-four ul li img {
  margin-bottom: 10px;
}
.find-application-four ul li:nth-child(2) {
  margin-top: -20px;
}
.find-application-four span,
.find-application-third span,
.find-application-heading span,
.find-application span {
  font-weight: 500;
  font-family: "montserratsemibold_italic";
}
.find-application-heading h2:before {
  content: "";
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  top: -20px;
  left: -40px;
  background-image: radial-gradient(
    circle at 50% 0px,
    rgb(128 135 223),
    rgb(35, 38, 59)
  );
}
.find-application-heading h2 {
  position: relative;
}
.find-application-heading h2 {
  -webkit-animation: mover2 2s infinite alternate;
  animation: mover2 2s infinite alternate;
}
#highprecision.components {
  display: none;
}
@-webkit-keyframes mover2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@keyframes mover2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
.pump-aca-logo {
  width: 28%;
}
.ipump-logo {
  position: relative;
  top: -5px;
}
h2.find-your-app {
  font-size: 35px;
  font-family: "montserratsemibold_italic";
  font-weight: 800;
  text-align: center;
}
.nav-item.dropdown a.active i {
  transform: rotate(180deg);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.nav-item.dropdown a i {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.vision {
  background: radial-gradient(
    100% 100% at 100% 0%,
    rgb(40 207 255) 0%,
    rgb(34 49 165) 100%
  );
  margin-top: 6em;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
}
.vision-mission img {
  background: #fff;
  padding: 12px;
  box-shadow: 0px 3px 13px -3px #c1c1c1;
  border-radius: 8px;
  margin-top: -85px !important;
  width: 100px;
  margin: 0 auto 15px;
}
.vision h3,
.vision h5,
.vision p {
  color: #fff;
}
#basic-navbar-nav .navbar-nav li:nth-child(3) img {
  margin-top: 0px;
}
.Toastify__close-button--colored {
  width: 13% !important;
  background: #576a99 !important;
  color: #fff;
  padding: 4px 5px 9px 6px !important;
}
.main-content h2 {
  font-family: "product_sansbold";
  font-size: 28px;
  color: #000;
}
.main-content p {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 30px;
  text-align: justify;
}
.main-content h3 {
  font-family: "product_sansbold";
}
.main-content ul {
  list-style: none;
}
.main-content ul li {
  position: relative;
  margin-bottom: 1em;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 30px;
  text-align: justify;
}
.main-content ul li:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  background: #0073e6;
  left: -16px;
  top: 9px;
  border-radius: 3px;
}
.main-content h4 {
  font-family: "product_sansbold";
  font-size: 22px;
  color: #000;
}
.main-content h5 {
  font-family: "product_sansbold";
  font-size: 20px;
  color: #000;
}
.main-content table tr td:nth-child(1) {
  background-color: #34518c;
  width: 250px;
  color: #fff;
  padding: 10px;
}
.main-content table tr td:nth-child(2) {
  background: #eee;
  padding: 10px;
}
.main-content table {
  box-shadow: 0 10px 10px #ccc;
  margin-bottom: 2em;
}
.main-content .overflow-hidden {
  overflow-x: auto;
}
.main-content ul li a {
  display: inline-block;
  width: 100%;
}
.team-item-brief p.team-item-title {
  display: inline-block;
}
.home-banner .border-radius img {
  border-radius: 7px;
}
.leftrequest img {
  border: 5px solid #fff;
}
.pdf-knowledge {
  position: relative;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  overflow-y: scroll;
  height: 750px;
}
.pdf-knowledge img {
  width: 100%;
}
.benefits ul li b {
  font-family: "montserratbold";
  font-style: italic;
}
.define {
  font-weight: bold;
}
.knowledge > a {
  color: #fff !important;
  cursor: pointer;
}
.knowledge .dropdown-menu {
  background: #576a99;
  position: absolute;
  opacity: 1;
  display: block;
  left: 0;
  min-width: 24rem;
}
.knowledge {
  position: relative;
}
.knowledge .dropdown-menu .dropdown-item {
  display: inline-block;
  padding: 8px 0;
}
.knowledge .dropdown-menu li {
  margin: 0;
}
.knowledge .dropdown-menu li a {
  padding: 8px 8px !important;
}
.knowledge.mob-menu .dropdown-menu li a {
  font-size: 17px !important;
}

.media > a {
  color: #fff !important;
  cursor: pointer;
}
.media .dropdown-menu {
  background: #576a99;
  position: absolute;
  opacity: 1;
  display: block;
  left: 0;
  min-width: 24rem;
}
.media {
  position: relative;
}
.media .dropdown-menu .dropdown-item {
  display: inline-block;
  padding: 8px 0;
}
.media .dropdown-menu li {
  margin: 0;
}
.media .dropdown-menu li a {
  padding: 8px 8px !important;
}
.media.mob-menu .dropdown-menu li a {
  font-size: 17px !important;
}

.star-tag {
  font-size: 14px;
}
.logo-pump {
  width: 95px;
}
.deploy-faster h1 {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 24px;
}
.chapter-drop li {
  width: 100%;
  display: block !important;
  padding: 0;
}
.chapter-drop {
  width: 100%;
}
.right-span i {
  color: #576a99;
}
.right-span {
  cursor: pointer;
  width: 25px;
  float: right;
  position: absolute;
  left: 22rem;
  top: 0;
  background: #fff;
  color: #000;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  z-index: 99999999;
}
.knowledge .dropdown-menu li:nth-child(2) {
  position: relative;
}
.chapter-drop {
  padding-left: 10px !important;
}
.chapter-drop li a:hover {
  color: #1e2125;
  background-color: #e9ecef;
}
.chapter-drop li a {
  width: 100%;
  display: block;
  padding: 2px 0 !important;
}
.benefits {
  padding: 50px 0 15px 0 !important;
}
.define {
  margin-right: 10px;
}
.chapter-drop li {
  border-bottom: 1px solid #7187bb;
}
.open-position h3 {
  font-family: "product_sansbold";
  font-size: 30px;
}
.position-head {
  border-radius: 0px;
  border-width: 1px;
  border-color: rgba(214, 214, 214, 0.5);
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 30px;
  border-left: 5px solid #57a3ff;
  padding-left: 20px;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transition: box-shadow 300ms ease 0ms;
  border-top-left-radius: 14px;
  border-left: 5px solid #3f96ff;
}
.position-head h4 {
  font-family: "product_sansbold";
  margin-bottom: 0;
}
.position-head a {
  background: #000;
  color: #fff;
  text-decoration: none;
  padding: 9px 22px;
}
.position-head a:hover {
  color: #fff;
}
.position-head ul {
  position: relative;
  margin: 25px 0 25px 20px;
  padding: 0;
}
.position-head ul li {
  font-weight: 600;
  margin: 10px 0;
  position: relative;
  list-style: none;
  padding-left: 10px;
}
.position-head a {
  text-transform: initial;
  letter-spacing: 1px;
  transition: all 0.2s ease, transform 0.6s ease;
  box-shadow: 0.2rem 0.8rem 1.6rem 0.2rem rgb(226 233 255);
  padding: 8px 12px;
  border: none;
  background: #4f66ad;
  color: #fff;
  font-size: 18px;
  border-radius: 7px;
  font-family: "Open Sans", sans-serif;
}
.position-head ul:before {
  content: "";
  position: absolute;
  left: -13px;
  width: 3px;
  top: 1px;
  background-image: linear-gradient(180deg, #e6e6e6 62%, #ffffff 100%);
  height: 100%;
  border-radius: 63px;
}
.position-head ul li:after {
  box-shadow: 2px 2px 7px #ccc;
  content: "";
  background: radial-gradient(
    100% 100% at 100% 0%,
    rgb(90, 218, 255) 0%,
    rgb(84, 104, 255) 100%
  );
  height: 12px;
  position: absolute;
  left: -17px;
  width: 12px;
  top: 8px;
  border-radius: 2px;
  transform: rotate(45deg);
}
.position-head .arrow-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  right: -18px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.position-head {
  position: relative;
  background: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.position-head:hover {
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 9px 6px -1px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}
.applynow {
  letter-spacing: 0;
}
.position-head ul,
.position-head .applynow {
  display: none;
  opacity: 0;
}
.arrow-btn.down-arrow {
  transform: rotate(90deg);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.list-style.list-style-block {
  display: inline-block;
  opacity: 1;
}
.applynow.list-style-block {
  display: inline-block;
  opacity: 1;
}
.contact-form textarea {
  box-shadow: 0px 8px 11px #0000001f;
}
.careerform {
  background: #252c42;
}
.career-form h2 {
  font-family: "product_sansbold";
  font-size: 30px;
  margin-bottom: 1.1em;
  color: #fff;
}
.career-form .formdemo .req-quote {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.career-form .formdemo .req-quote .req-field {
  width: 49%;
}
.career-form .formdemo input {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 5%);
  padding: 10px 13px;
  border: 1px solid #ffffff;
  font-size: 1.3rem;
  color: #6a6a6a;
  margin-bottom: 0px;
  box-shadow: 0.2rem 0.8rem 1.6rem 0.2rem #dfe7ff00;
}
.career-form .formdemo textarea {
  min-height: 150px;
  box-shadow: 0.2rem 0.8rem 1.6rem 0.2rem #dfe7ff00;
  box-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 5%);
}
.career-form .btnContact {
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 50%;
  transition: all 0.2s ease, transform 0.6s ease;
  padding: 12px;
  border: none;
  background: #4f66ad;
  color: #fff;
  font-size: 18px;
  border-radius: 7px;
  font-family: "robotobold";
}
.career-form .btnContact:hover {
  background: #5a2791;
  box-shadow: 0.2rem 0.8rem 1.6rem 0.2rem #252c4230;
}
.listingjobs {
  background: url("/public/images/footer-waves.svg") no-repeat center;
  background-size: cover;
}
iframe {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
}
.social_media ul {
  margin: 0;
  padding: 0;
}
.social_media ul li {
  list-style: none;
  display: inline-block;
  margin: 0 20px 0 0;
}
.social_media ul li a {
  color: #fff;
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
}
.social_media ul li a:hover {
  background: #fff;
  color: #1c0732;
}
.point-phara p {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
}

/*========================== Media Page starts here ==========================*/

.media-sec a {
  text-decoration: none;
}
.media-sec li {
  padding-bottom: 15px;
  list-style-type: none;
  /* border-top: none !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.media-sec li:nth-child(even) {
  /*background: $lightBlue;*/
  line-height: 1.2;
}
.media-sec li:nth-child(odd) {
  /*background: $lightGrey;*/
  line-height: 1.2;
}
.media-sec li h5 {
  line-height: 20px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.media-sec li a:hover {
  color: #45a29e;
}
.media-sec a li h5 {
  margin: 5px 0 10px 0 !important;
  font-size: 1rem;
}

/*========================== Media Page ends here ==========================*/

@media only screen and (max-width: 1920px) {
  .page-tit h1 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 1366px) {
  .knowledgetitle {
    padding: 300px 0 0px 0;
  }
  .pagetitle,
  .parametertitle,
  .productsolution,
  .applicationtitle,
  .abouttitle,
  .credentialstitle,
  .teamtitle,
  .contacttitle,
  .abouttitle,
  .mediatitle {
    padding: 320px 0 0px 0;
  }
  .monitored-heading h2,
  .trusted-by-heading h2,
  .article-heading h2,
  .benefits h2 {
    font-size: 26px !important;
  }
  .deploy-faster h1 {
    font-size: 19px !important;
    line-height: 30px !important;
  }
  .box-info img {
    width: 60px;
  }
  .box-info ul li p {
    font-size: 15px;
  }
  .box-info {
    margin-top: 1em;
  }
  .monitored-heading h2,
  .trusted-by-heading h2,
  .article-heading h2,
  .benefits h2 {
    font-size: 25px;
  }
  .benefits-logo img {
    width: 115px;
  }
  .benefits ul li img {
    width: 100px;
  }
  .solution-tabs .nav-item h4 {
    font-size: 16px;
  }
  .academy-heading h2 {
    font-family: "montserratbold";
    color: #fff;
    font-size: 30px;
  }
  .academy-heading p {
    font-size: 18px;
    margin-bottom: 0.4em !important;
  }
  .works-tabs .row > .col-sm-12 {
    flex: 0 0 auto;
    width: 80%;
    margin: 0 auto;
  }
  .explore-deploy.mt-5 {
    margin-top: 1rem !important;
  }
  .get-demo.mt-5 {
    margin-top: 2rem !important;
  }
  .tab-horizontal .flex-column .nav-item a {
    font-size: 18px;
  }
  .pagetitle,
  .parametertitle,
  .productsolution,
  .applicationtitle,
  .abouttitle,
  .credentialstitle,
  .teamtitle,
  .contacttitle,
  .abouttitle,
  .mediatitle {
    padding: 250px 0 0px 0;
  }
  .phara-info img {
    width: 150px;
  }
  #precision .list-component ul li img {
    width: 80%;
    margin: 0 auto;
  }
  #precision .list-component ul li {
    text-align: center;
  }
  #precision .list-component ul li h4 {
    text-align: left;
  }
  .requestbanner {
    padding: 40px 0 40px 0;
  }
  .contact-form textarea {
    height: 150px;
  }
  .home-banner {
    padding-top: 5vh !important;
  }
  .home-banner {
    position: relative;
    padding-top: 120px;
    height: calc(100vh - 26vh);
  }
  .deploy-faster {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .benefits {
    padding: 50px 0;
  }
  .precision-monitoring {
    padding: 50px 0;
  }
  .home-banner {
    height: auto;
  }
  .home-banner {
    padding-top: 5vh !important;
    padding-bottom: 5vh;
  }
  .academy-works {
    padding: 0px 0 50px 0;
  }
  .we-deliver {
    padding: 50px 0 80px 0;
  }
  .academy-heading h2 {
    margin-bottom: 0 !important;
  }
  .tab-horizontal,
  #gap-work {
    margin-top: 1em !important;
  }
  .monitored-ipumpnet {
    padding: 50px 0 0px 0 !important;
  }
  #exclusivity {
    padding-top: 30px;
  }
  .exclusivity {
    padding: 30px 0;
  }
  .main-footer {
    padding-top: 40px;
  }
  .solution-tabs .nav-item p {
    line-height: 24px;
  }
  .we-deliver {
    padding: 50px 0 50px 0 !important;
  }
  .box-info ul li p {
    margin-bottom: 0;
  }
  .benefits ul li img {
    width: 90px;
  }
  .precision-inf p {
    font-size: 18px;
    line-height: 26px;
  }
  .solution-tabs .nav-pills .nav-item {
    margin: 0 0 0em 0;
  }
  .solution-tabs {
    margin-top: 1em;
  }
  .solution-tabs .nav-item p {
    line-height: 22px;
  }
  .solution-tabs .nav-pills .nav-link {
    padding: 18px 25px;
  }
  .solution-tabs .nav-pills .nav-item:nth-child(1):before {
    top: 10px;
  }
  .monitored-points ul li {
    margin-bottom: 1.8em;
  }
  .we-deliver {
    padding: 50px 0 80px 0;
  }
  .academy-heading h2 {
    margin-bottom: 0 !important;
  }
  .tab-horizontal,
  #gap-work {
    margin-top: 1em !important;
  }
  .monitored-ipumpnet {
    padding: 30px 0 0px 0 !important;
  }
  #exclusivity {
    padding-top: 30px;
  }
  .exclusivity {
    padding: 30px 0;
  }
  .main-footer {
    padding-top: 40px;
  }
  .operating-parameters ul li {
    margin: 1% 1% 1% 1%;
  }
  .article-heading h4,
  .trusted-by-heading h4,
  .monitored-heading h4 {
    font-size: 24px !important;
  }
  .phara-info p {
    font-size: 16px !important;
    line-height: 26px;
  }
  .iiot-based p {
    font-size: 16px;
    line-height: 26px;
  }
  .pumpnet-description {
    padding: 60px 0;
  }
  .iiot-manage {
    padding: 10px 0 40px 0;
  }
  .components {
    padding-top: 30px;
  }
  .list-component ul li h4 {
    font-size: 1.2rem;
  }
  .list-component ul li {
    width: 18%;
  }
  .about-products {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .table-design {
    margin: 50px 0;
  }
  .list-product {
    margin-top: 50px;
  }
  h2.find-your-app {
    font-size: 30px;
  }
  .pumpperformance h2.mb-5.text-center {
    margin-bottom: 30px !important;
    font-size: 20px;
  }
  .main-footer .container #bottom_footer {
    margin-top: 150px;
  }
  .contact-form h4 {
    font-size: 17px;
    line-height: 24px;
  }
  .contact-form textarea {
    height: 120px;
  }
  .precision-inf p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media only screen and (max-height: 768px) {
  .works-tabs .row > .col-sm-12 {
    width: 70% !important;
    margin: 0 auto;
  }
  .benefits ul li p {
    line-height: 22px;
  }
  .precision-inf p {
    font-size: 18px;
    line-height: 26px;
  }
  .deploy-faster h1 {
    font-size: 19px !important;
    line-height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .box-info img {
    width: 60px;
  }
  .box-info ul li p {
    font-size: 15px;
    margin-bottom: 0;
  }
  .box-info {
    margin-top: 1em;
  }
  .monitored-heading h2,
  .trusted-by-heading h2,
  .article-heading h2,
  .benefits h2 {
    font-size: 22px;
  }
  .benefits-logo img {
    width: 95px;
  }
  .benefits ul li img {
    width: 90px;
  }
  .solution-tabs .nav-item h4 {
    font-size: 16px;
  }
  .academy-heading h2 {
    font-family: "montserratbold";
    color: #fff;
    font-size: 30px;
  }
  .academy-heading p {
    font-size: 18px;
    margin-bottom: 0.4em !important;
  }
  .works-tabs .row > .col-sm-12 {
    flex: 0 0 auto;
    width: 80%;
    margin: 0 auto;
  }
  .explore-deploy.mt-5 {
    margin-top: 1rem !important;
  }
  .get-demo.mt-5 {
    margin-top: 2rem !important;
  }
  .tab-horizontal .flex-column .nav-item a {
    font-size: 18px;
  }
  .pagetitle,
  .parametertitle,
  .productsolution,
  .applicationtitle,
  .abouttitle,
  .credentialstitle,
  .teamtitle,
  .contacttitle,
  .mediatitle,
  .presstitle {
    padding: 370px 0 0px 0;
  }
  .phara-info img {
    width: 150px;
  }
  #precision .list-component ul li img {
    width: 80%;
    margin: 0 auto;
  }
  #precision .list-component ul li {
    text-align: center;
  }
  #precision .list-component ul li h4 {
    text-align: left;
  }
  .requestbanner {
    padding: 40px 0 40px 0;
  }
  .contact-form textarea {
    height: 150px;
  }
  .home-banner {
    padding-top: 5vh !important;
  }
  .home-banner {
    position: relative;
    padding-top: 120px;
    height: calc(100vh - 26vh);
  }
  .deploy-faster {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .benefits {
    padding: 50px 0 15px 0 !important;
  }
  .precision-monitoring {
    padding: 50px 0;
  }
  .home-banner {
    height: auto;
  }
  .home-banner {
    padding-top: 5vh !important;
    padding-bottom: 5vh;
  }
  .academy-works {
    padding: 0px 0 50px 0;
  }
  .we-deliver {
    padding: 50px 0 80px 0;
  }
  .academy-heading h2 {
    margin-bottom: 0 !important;
  }
  .tab-horizontal,
  #gap-work {
    margin-top: 1em !important;
  }
  .monitored-ipumpnet {
    padding: 30px 0 0px 0 !important;
  }
  #exclusivity {
    padding-top: 30px;
  }
  .exclusivity {
    padding: 30px 0;
  }
  .main-footer {
    padding-top: 40px;
  }
  .operating-parameters ul li {
    margin: 1% 1% 1% 1%;
  }
  .article-heading h4,
  .trusted-by-heading h4,
  .monitored-heading h4 {
    font-size: 24px !important;
  }
  .phara-info p {
    font-size: 16px !important;
    line-height: 26px;
  }
  .iiot-based p {
    font-size: 16px;
    line-height: 26px;
  }
  .pumpnet-description {
    padding: 60px 0;
  }
  .iiot-manage {
    padding: 40px 0 40px 0;
  }
  .components {
    padding-top: 30px;
  }
  .list-component ul li h4 {
    font-size: 1.2rem;
  }
  .list-component ul li {
    width: 18%;
  }
  .about-products {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .table-design {
    margin: 50px 0;
  }
  .list-product {
    margin-top: 50px;
  }
  h2.find-your-app {
    font-size: 30px;
  }
  .pumpperformance h2.mb-5.text-center {
    margin-bottom: 30px !important;
    font-size: 20px;
  }
  .main-footer .container #bottom_footer {
    margin-top: 250px;
  }
  .contact-form h4 {
    font-size: 17px;
    line-height: 24px;
  }
  .contact-form textarea {
    height: 120px;
    box-shadow: 0px 8px 11px #0000001f;
  }
  .precision-inf p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 850px) {
  .team-item {
    width: calc(25% - 20px);
  }
}

@media only screen and (max-width: 1600px) {
  .logos ul li:nth-last-child(1),
  .logos ul li:nth-last-child(2) {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .trusted-by {
    overflow: hidden;
  }
  .home-banner {
    padding-top: 15vh;
  }
  .banner-information h3 {
    font-size: 18px;
  }
  .logo-carousel h2 {
    font-size: 20px;
  }
  .slick-slide img {
    width: 80% !important;
  }
  .main_header #basic-navbar-nav .navbar-nav a,
  .main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li a {
    font-size: 20px;
  }
  .home-banner {
    background-size: cover;
  }
  .precision-inf h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .we-deliver .deliver-heading h2 {
    font-size: 1.8em;
  }
  .deploy-faster h1 {
    font-size: 28px;
    line-height: 45px;
  }
  .article-heading h4,
  .trusted-by-heading h4,
  .monitored-heading h4 {
    font-size: 28px;
  }
  .monitored-heading h2,
  .trusted-by-heading h2,
  .article-heading h2,
  .benefits h2 {
    font-size: 32px;
  }
  .page-tit h1 {
    font-size: 1.8em;
  }
  .boost-text p {
    font-size: 1.6em;
  }
  .monitored-points ul li .content-right h4 {
    font-size: 19px;
  }
  .phara-info p {
    font-size: 18px;
  }
  .iiot-based h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .getting h2 {
    font-size: 32px;
  }
  .pagetitle,
  .abouttitle,
  .credentialstitle,
  .teamtitle,
  .contacttitle,
  .applicationtitle,
  .parametertitle,
  .productsolution,
  .technologytitle,
  .careertitle,
  .mediatitle {
    background-size: cover;
  }
  .page-tit h1 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 1366px) {
  .logos ul li:nth-last-child(1),
  .logos ul li:nth-last-child(2) {
    display: none;
  }
  .top-right ul li {
    margin: 0 20px;
  }
  .trusted-by {
    overflow: hidden;
  }
  .trusted-by .container:before,
  .trusted-by .container::after {
    z-index: 0 !important;
  }
  .main_header #basic-navbar-nav .navbar-nav a {
    color: #1d2031;
    padding-right: 23px;
    font-size: 19px;
  }
  .button-banner a:nth-child(1) {
    font-size: 15px;
  }
  .find-application-four span,
  .find-application-third span,
  .find-application-heading span,
  .find-application span {
    font-size: 14px;
    line-height: 20px;
  }
  .find-application-heading ul li h2 {
    font-size: 50px;
  }
  .home-banner {
    padding-top: 22vh;
  }
}

@media only screen and (max-width: 1080px) {
  .find-application-heading ul li h2 {
    font-size: 40px;
  }
  .find-application-heading h2:before {
    top: -30px;
    left: -50px;
  }
  .find-application-four {
    margin: 0px auto;
    width: 50%;
  }
  .find-application-third {
    width: 70%;
  }
}

@media (min-width: 425px) and (max-width: 700px) {
  .team-item-description {
    width: calc(200% + 40px);
  }
  .team-item:nth-of-type(2n + 2) .team-item-description {
    margin-left: calc(-100% - 20px);
  }
}

@media (min-width: 700px) and (max-width: 850px) {
  .team-item:nth-child(3n + 1):nth-last-child(-n + 3) ~ .team-item {
    margin-left: 30px;
    margin-right: auto;
  }
  .team-item-description {
    width: calc(300% + 60px);
  }
  .team-item:nth-of-type(3n + 2) .team-item-description {
    margin-left: calc(-100% - 60px / 2);
  }
  .team-item:nth-of-type(3n + 3) .team-item-description {
    margin-left: calc(-200% - 60px);
  }
  .team-item-description-main {
    margin-left: calc(-62% - 80px / 2);
  }
  .team-item-description-main {
    width: calc(226% + 80px);
  }
}

@media (min-width: 850px) {
  .team-item-description {
    width: calc(400% + 70px);
  }
  .team-item:nth-of-type(4n + 2) .team-item-description {
    margin-left: calc(-100% - 80px / 3);
  }
  .team-item:nth-of-type(4n + 3) .team-item-description {
    margin-left: calc(-209% - 80px / 3);
  }
  .team-item:nth-of-type(4n + 4) .team-item-description {
    margin-left: calc(-300% - 80px);
  }
  .team-item {
    width: calc(25% - 20px);
  }
  .team-grid .team-item:nth-last-child(2) .team-item-description {
    width: calc(300% + 40px) !important;
  }
  .team-grid .team-item:nth-last-child(3) .team-item-description {
    width: calc(200% + 20px) !important;
  }
  .team-grid .team-item:nth-last-child(4) .team-item-description {
    width: calc(200% + 20px) !important;
  }
  .team-item-description-main {
    margin-left: calc(-155% - 80px / 4);
  }
}

@media only screen and (max-width: 1200px) {
  .main_header #basic-navbar-nav .navbar-nav a {
    font-size: 17px;
  }
  .logo-carousel h2 {
    font-size: 20px;
  }
  .logos ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    text-align: right;
  }
  .logos ul li:nth-last-child(3) {
    display: none;
  }
  .home-banner {
    background-position: right top;
  }
  .button-banner a:nth-child(2) {
    margin-right: 0;
  }
  .top-mail ul li a {
    font-size: 17px;
  }
}

@media only screen and (min-device-width: 991px) and (max-device-width: 1200px) {
  .top-right ul li {
    margin: 0 20px;
  }
  .top-right ul li a {
    font-size: 16px;
  }
  .top-mail ul li a {
    font-size: 16px;
  }
  .main_header .navbar-brand img {
    width: 80%;
  }
  .main_header #basic-navbar-nav .navbar-nav a {
    padding-right: 8px;
  }
  .main_header #basic-navbar-nav .navbar-nav a:last-child {
    padding: 8px 18px;
  }
  .logo-carousel h2 {
    font-size: 17px;
  }
  .logos ul li {
    display: inline;
    margin: 0 15px;
  }
  .button-banner a {
    font-size: 13px !important;
  }
  .we-deliver .deliver-heading h2,
  .deploy-faster h2,
  .monitored-heading h2,
  .trusted-by-heading h2,
  .article-heading h2 {
    font-size: 32px;
  }
  .academy-heading h2,
  .getting h2 {
    font-size: 36px;
  }
  .deploy-faster h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .social_media {
    margin-bottom: 30px;
  }
  .knowledge .dropdown-menu {
    min-width: 21rem;
  }
  .knowledgetitle {
    padding: 150px 0 0px 0;
    position: relative;
    background-size: contain;
    background-position-x: center;
  }
  .vision .border-right {
    border: none;
  }
  .privacytitle {
    background-position-x: right;
    background-position-y: bottom;
  }
  .termstitle {
    background-position-x: right;
    background-position-y: bottom;
  }
  .main_header #basic-navbar-nav .navbar-nav a {
    color: #fff;
  }
  .top-bar .col-md-7 {
    display: none;
  }
  .top-bar .top-right {
    display: none;
  }
  .top-bar .top-mail {
    text-align: center;
  }
  .home-banner {
    height: auto;
    padding-top: 100px;
  }
  .logo-carousel {
    position: relative;
    margin-top: 4em;
  }
  .logo-heading h2 {
    text-align: center;
  }
  .logos ul {
    text-align: center;
  }
  .logos ul li {
    display: inline;
    margin: 0 0px;
  }
  .deploy-faster h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .box-info ul li {
    width: 100%;
  }
  .box-info ul li img {
    left: 38%;
  }
  .box-info ul li:nth-child(2) {
    margin: 7em 0;
  }
  .academy-heading h2 {
    font-size: 26px;
  }
  .academy-heading p {
    font-size: 18px;
  }
  #gap-work {
    margin-top: 3em !important;
  }
  .monitored-heading h2 {
    font-size: 26px;
  }
  .monitored-heading h4 {
    font-size: 24px;
  }
  .monitored-points ul li .wrap-list .content-right {
    margin-top: 1.5em;
    width: 100%;
  }
  .monitored-points ul li .content-right h4 {
    font-size: 18px;
  }
  .monitored-points ul li .content-right p {
    font-size: 16px;
  }
  .trusted-by-heading h2,
  .article-heading h2,
  .getting h2 {
    font-size: 26px;
  }
  .trusted-by-heading h4,
  .article-heading h4 {
    font-size: 24px;
  }
  .getting p {
    font-size: 24px;
  }
  .article-data ul li {
    width: 100%;
  }
  .article-data ul li:nth-child(2) {
    margin: 2em 0;
  }
  .trusted-by .container::after,
  .trusted-by .container::before {
    display: none;
  }
  .footer-left,
  .footer-right {
    text-align: center;
  }
  .footer-right ul,
  .footer-email,
  .floating-form {
    float: none;
    text-align: center;
  }
  .article-data ul {
    padding: 0;
  }
  .home-banner {
    height: auto;
    padding-top: 200px;
  }
  .home-banner .container {
    max-width: 90%;
  }
  .navbar-light .navbar-toggler {
    color: #ffffff;
    background: #fff;
  }
  .navbar-collapse.collapse.show .navbar-nav {
    background: #252c42;
    padding: 15px 0;
  }
  .navbar-collapse.collapse.show .align-items-center {
    align-items: baseline;
  }
  .main_header #basic-navbar-nav .navbar-nav a {
    padding: 0;
    width: 100%;
  }
  .navbar-nav {
    padding: 0 8px !important;
  }
  .page-tit h1 {
    text-align: center;
    font-size: 32px;
  }
  .breadcrumb-page {
    text-align: center;
    margin-bottom: 15px;
  }
  .iiot-based h2 {
    margin-top: 20px;
  }
  .list-component ul li {
    width: 48%;
  }
  .benefits h2 {
    color: #252c42;
    font-size: 26px;
  }
  .phara-info p {
    margin-top: 20px;
    text-align: left;
  }
  .benefits ul li {
    width: 48%;
  }
  .requestform {
    margin-top: 25px;
  }
  .lesswork ul {
    flex-wrap: wrap;
  }
  .lesswork ul li {
    width: 48%;
  }
  .lesswork ul li img {
    margin-top: 15px;
  }
  .navbar-collapse .navbar-nav > li {
    width: 100%;
    text-align: left;
    padding: 12px 0;
    border-bottom: 1px solid #ccc;
  }
  .navbar-collapse .navbar-nav > li a {
    display: block;
  }
  .main_header #basic-navbar-nav .navbar-nav li:nth-last-child(1) a {
    display: inline-block;
  }
  .navbar-collapse .navbar-nav .nav-item.dropdown > a {
    display: inline-block !important;
    width: auto !important;
  }
  .navbar-collapse .navbar-nav .nav-item.dropdown {
    border-bottom: 1px solid #ccc;
  }
  .main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown a:nth-child(2) {
    top: 6px;
    position: absolute;
    right: 7px;
    font-size: 30px;
  }
  .main_header #basic-navbar-nav .navbar-nav a {
    font-size: 20px;
  }
  .main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul {
    background: #fff;
    box-shadow: 0 0 30px #252c42;
  }
  .main_header #basic-navbar-nav .navbar-nav .nav-item.dropdown ul li a {
    color: #576a99;
  }
  .main_header
    #basic-navbar-nav
    .navbar-nav
    .nav-item.dropdown
    ul
    li:nth-last-child(1)
    a {
    color: #576a99;
  }
  .dropdown ul.show {
    background: #272e46;
    width: 100%;
  }
  .pumpnet-description {
    padding: 80px 0 40px 0;
  }
  .arrow-box {
    margin-left: 10em;
  }
  .requestform {
    max-width: 100%;
  }
  .leftrequest img {
    width: 100%;
  }
  .whatsapp {
    padding-bottom: 45px;
  }
  .points {
    margin-top: 25px;
  }
  .operating-parameters ul li {
    width: 48%;
  }
  .ipumpnetpoint ul li span {
    width: 100%;
  }
  .ipumpnetpoint ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ipumpnetpoint ul li {
    width: 23%;
  }
  .applicationtitle {
    background-position: center !important;
  }
  .parametertitle {
    background-position: center;
  }
  .productsolution {
    background-position: center;
    background-position-x: 88%;
  }
  .technologytitle {
    background-position: center;
  }
  .abouttitle,
  .careertitle,
  .mediatitle {
    background-position: center;
  }
  .credentialstitle {
    background-position: center;
    background-position-x: left;
  }
  .teamtitle {
    background-position: center;
  }
  .contacttitle {
    background-position: center;
    background-position-x: right;
  }
  .box-info ul li {
    width: 100%;
    margin-bottom: 2em !important;
  }
  .box-info ul li:nth-child(2) {
    margin: 0%;
  }
  .explore-deploy.mt-5 {
    margin-top: 1rem !important;
  }
  .box-info ul li:nth-child(even) {
    width: 100%;
  }
  .list-product ul li {
    width: 30%;
    margin: 10px 10px;
  }
  .list-product ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .find-application ul li {
    text-align: center;
    margin: 0 10px;
  }
  .find-application ul {
    justify-content: center;
  }
  .find-application-heading ul {
    justify-content: space-between;
    align-items: center;
  }
  .find-application-heading {
    margin: 80px auto;
    width: 100%;
  }
  .find-application-third {
    width: 100%;
  }
  .find-application-third ul li:nth-child(2) {
    margin-top: 0;
  }
  .find-application-four {
    margin: 0px auto;
    width: 70%;
  }
  .find-application-four ul li:nth-child(2) {
    margin-top: 0;
  }
  .find-your-app {
    display: block !important;
    font-size: 50px !important;
    margin-bottom: 30px;
  }
  .main-footer .container #bottom_footer {
    margin-top: 120px;
  }
  .button-banner {
    padding-bottom: 35px;
  }
  .find-application ul li {
    width: 30%;
    margin-bottom: 2% !important;
  }
  .nav-item.dropdown ul li a {
    color: #1d2031 !important;
  }
  .mob-menu {
    display: block;
  }
  .main_header #basic-navbar-nav .navbar-nav li:nth-last-child(1) a {
    background: none;
    padding: 0;
  }
  .navbar-collapse.closed {
    opacity: 0;
  }
  #desktop-menu {
    display: none;
  }
  #mobile-menu {
    display: block;
  }
  #mobile-menu #basic-navbar-nav {
    display: block;
    background: #252c42;
  }
  .phone-toggle {
    border: none;
    color: #fff;
    font-weight: 300;
    cursor: pointer;
    padding: 9px 15px;
    border-radius: 5px;
    transition: box-shadow 0.15s ease 0s, transform 0.15s ease 0s;
    will-change: box-shadow, transform;
    background: radial-gradient(
      100% 100% at 100% 0%,
      rgb(90, 218, 255) 0%,
      rgb(84, 104, 255) 100%
    );
    box-shadow: rgb(45 35 66 / 40%) 0px 2px 4px,
      rgb(45 35 66 / 30%) 0px 7px 13px -3px,
      rgb(58 65 111 / 50%) 0px -3px 0px inset;
  }
  video {
    width: 100%;
  }
  .home-banner {
    height: auto;
    padding-top: 7vh !important;
    padding-bottom: 7vh;
  }
  .right-span {
    right: 5px;
    top: 5px;
    left: auto;
  }
  .dropdown-menu .chapter-drop li a:hover {
    color: #000 !important;
  }
}

@media only screen and (min-width: 850px) {
  .team-item-description {
    width: calc(400% + 70px);
  }
  .team-item-description-main {
    width: calc(400% + 70px);
  }
  .team-item:nth-of-type(4n + 2) .team-item-description {
    margin-left: calc(-100% - 80px / 3);
  }
  .team-item:nth-of-type(4n + 3) .team-item-description {
    margin-left: calc(-209% - 80px / 3);
  }
  .team-item:nth-of-type(4n + 4) .team-item-description {
    margin-left: calc(-300% - 80px);
  }
}

@media only screen and (max-width: 767px) {
  .home-banner {
    height: auto;
    padding-top: 19vh;
  }
  .main-content p {
    text-align: left;
  }
  .main-content table tr td:nth-child(2) {
    width: 400px;
  }
  .carousel-trusted .customer-message {
    flex-direction: column;
  }
  .carousel-trusted .customer-message .left-message {
    width: 100%;
  }
  .carousel-trusted .customer-message .right-message {
    width: 100%;
  }
  .carousel-trusted .customer-message .right-message a {
    position: relative;
    right: auto;
    bottom: 0;
  }
  .tab-horizontal .flex-column .nav-item a {
    font-size: 17px;
  }
  .designed {
    justify-content: center;
    flex-direction: column;
  }
  .designed p {
    margin-bottom: 1em;
  }
  .designed p br {
    display: none;
  }
  .copy {
    margin-top: 5px;
  }
  .logo-heading h2 br {
    display: none;
  }
  .terma-condition,
  .copy p {
    text-align: center;
  }
  .iiot-based h2 {
    font-size: 28px;
  }
  .boost-text p {
    font-size: 25px;
  }
  .ipumpnetpoint ul li {
    width: 45%;
  }
  #lastname {
    margin-top: 1rem;
  }
  .list-product ul li {
    width: 28%;
    margin: 10px 10px;
  }
  .find-application ul {
    flex-wrap: wrap;
  }
  .find-application ul li,
  .find-application-third {
    width: 100%;
  }
  .find-application ul li:nth-child(1),
  .find-application-heading ul li:nth-child(1),
  .find-application-third ul li:nth-child(1),
  .find-application-third ul li:nth-child(2),
  .find-application-four ul li:nth-child(1) {
    margin-bottom: 30px;
  }
  .find-application-heading ul li:nth-child(1),
  .find-application-heading ul li,
  .find-application-third ul li {
    width: 100%;
  }
  .find-application-heading ul,
  .find-application-third ul,
  .find-application-third ul,
  .find-application-four ul {
    flex-wrap: wrap;
  }
  .find-application-heading ul li:nth-child(2) {
    display: none;
  }
  .find-application-heading {
    margin: 0px auto;
    width: 100%;
  }
  .find-application-four ul li {
    width: 100%;
  }
  .find-application-third {
    margin: 30px auto;
  }
  .find-application ul li {
    margin-bottom: 5% !important;
  }
  .banner-information {
    margin-top: 1.2em;
  }
  .home-banner {
    height: auto;
    padding-top: 7vh !important;
    padding-bottom: 7vh;
  }
  .right-span {
    right: 5px;
    top: 5px;
    left: auto;
  }
  .team-aall .team-grid .team-item {
    width: 48%;
  }
  .team-item-main.is--active .team-item-description-main {
    display: block;
    width: calc(155% + 150px);
    margin-left: calc(-55%);
  }

  .pagetitle:before,
  .abouttitle:before,
  .mediatitle:before .productsolution:before,
  .parametertitle:before,
  .applicationtitle::before,
  .credentialstitle:before,
  .teamtitle::before,
  .careertitle::before,
  .contacttitle::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(20, 61, 88, 0) 50%, #0d1d44 100%);
    z-index: 0;
  }
  .pagetitle,
  .abouttitle,
  .productsolution,
  .parametertitle,
  .applicationtitle,
  .credentialstitle,
  .teamtitle,
  .careertitle,
  .contacttitle,
  .mediatitle {
    padding: 150px 0 0px 0;
  }
}

@media only screen and (max-width: 700px) {
  .team-item-main {
    width: calc(50%);
    margin: 0 auto;
  }
}

@media only screen and (max-width: 576px) {
  .order-xs-2 {
    order: 2 !important;
  }
  .order-xs-1 {
    order: 1 !important;
  }
  .home-banner {
    padding-top: 120px;
  }
  .list-product ul {
    justify-content: space-between;
  }
  .list-product ul li {
    width: 48%;
    margin: 10px 0;
  }
  .sayhlo br {
    display: none;
  }
  .sayhlo h3 {
    font-size: 18px;
  }
  .home-banner {
    height: auto;
    padding-top: 19vh;
  }
  .deploy-faster h1 {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .logo-pump {
    width: 95px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
  .banner-information p {
    margin-top: 0.8em;
    color: #071946;
    font-family: "open_sanssemibold_italic";
    font-size: 15px;
    margin-bottom: 0.3em;
  }
}

@media only screen and (max-width: 480px) {
  .breadcrumb-page ul li:nth-child(2):before {
    height: 20px;
  }
  .tab-horizontal .flex-column .nav-item a {
    font-size: 14px;
  }
  .banner-information h3 {
    font-size: 19px;
    line-height: 26px;
  }
  .precision-inf h4 {
    font-size: 22px;
    line-height: 32px;
  }
  .we-deliver:before,
  .academy-works:before,
  .trusted-by:before {
    clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 93%);
  }
  .footer-email .email-control {
    min-width: 180px;
  }
  .lesswork ul li {
    width: 100%;
  }
  .iiot-based h2 {
    font-size: 25px;
  }
  .operating-parameters ul li {
    width: 100%;
  }
  .pumpperformance ul li {
    width: 100%;
  }
  .pumpperformance ul {
    flex-wrap: wrap;
  }
  .list-product ul li {
    width: 100%;
  }
  .about-products {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .find-your-app {
    font-size: 40px !important;
  }
  .monitored-points ul li {
    width: 100%;
    text-align: center;
  }
  .monitored-points ul li .wrap-list {
    justify-content: center;
  }
  .home-banner {
    height: auto;
    padding-top: 7vh !important;
    padding-bottom: 7vh;
  }
  .right-span {
    right: 5px;
    top: 5px;
    left: auto;
  }
  .team-item-main {
    width: calc(75%);
    margin: 0 auto;
  }
  .team-item-main.is--active .team-item-description-main {
    display: block;
    width: calc(130%);
    margin-left: calc(-15%);
  }
}

@media only screen and (max-width: 425px) {
  .button-banner a {
    width: 100%;
    display: inline;
  }
  .button-banner a:nth-child(2) {
    margin-top: 25px;
  }
  .logo-carousel {
    position: relative;
    margin-top: 2em;
  }
  .tab-horizontal .flex-column .back-wrap {
    flex-direction: column;
  }
  .box-info ul li img {
    left: 34%;
  }
  .top-to-btm {
    right: -67px;
  }
  .footer-left img {
    width: 100%;
  }
  .footer-right ul {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .footer-right ul li {
    width: 46%;
    margin: 7px 0;
  }
  .list-component ul li {
    width: 100%;
  }
  .benefits ul li {
    width: 100%;
  }
  .navbar a {
    width: 70%;
  }
  .navbar img {
    width: 100%;
  }
  .footer-left .footer-mail ul li:nth-child(1) {
    margin-right: 0;
    width: 100%;
  }
  .getting .get-demo a {
    display: block;
    margin-right: 0 !important;
  }
  .getting .get-demo a:nth-child(1) {
    margin-bottom: 15px;
  }
  .boost-text p {
    font-size: 20px;
  }
  .top-to-btm {
    right: -27px;
  }
  .benefits ul li {
    margin-bottom: 15px;
  }
  .ipumpnetpoint ul li {
    width: 51% !important;
    clear: both;
  }
  .ipumpnetpoint ul {
    justify-content: center;
    flex-wrap: wrap;
  }
  .address ul li img {
    width: 60%;
  }
  #basic-navbar-nav .navbar-nav li:nth-child(3) img {
    width: 25% !important;
  }
  .home-banner {
    height: auto;
    padding-top: 7vh !important;
    padding-bottom: 7vh;
  }
  #demo .req-quote {
    flex-direction: column;
  }
  #demo .req-quote .req-field {
    width: 100%;
    margin-bottom: 10px;
  }
  #msg-req {
    margin-bottom: 10px;
  }
  .right-span {
    right: 0;
    top: 5px;
  }
  .pump-aca-logo {
    width: 34%;
  }
  .team-aall .team-grid .team-item {
    width: 100%;
  }
  .team-item-main.is--active .team-item-description-main {
    display: block;
    width: calc(100%);
    margin-left: 0;
  }
  .team-item.is--active .team-item-description {
    display: block;
    width: 100%;
  }
  .team-item-main {
    width: calc(100%);
    margin: 0 auto;
  }
  .parametertitle {
    background-position: center;
  }
  .address ul li {
    width: 50%;
  }
  .whatsapp a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  .whatsapp img {
    position: relative;
    left: 0;
    top: 0;
    width: 37px;
    margin-right: 10px;
  }
  .whatsapp a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .whatsapp {
    padding-left: 0;
  }
}

@media only screen and (max-width: 380px) {
  .navbar-brand {
    max-width: 270px;
    margin-right: 0 !important;
  }
  .banner-information img {
    width: 70%;
    margin-top: 15%;
  }
  .monitored-points ul li .content-right h4,
  .monitored-points ul li .wrap-list .content-right a,
  .monitored-points ul li .content-right p {
    font-size: 15px;
  }
  .get-demo a {
    display: block;
  }
  .getting .get-demo a:nth-child(1) {
    margin-right: 0;
  }
  .getting .get-demo a:nth-child(2) {
    margin-top: 15px;
  }
  .main-footer .container #bottom_footer {
    margin-top: 120px;
  }
  .footer-email .email-control,
  .footer-email button {
    min-width: 100%;
  }
  .footer-email button {
    margin: 8px 0;
  }
  .top-mail ul li:nth-child(1),
  .top-mail ul li:nth-child(2),
  .top-mail ul li {
    display: block !important;
    margin: 0;
  }
  .top-mail ul li:nth-child(2) {
    margin-top: 10px;
  }
  .top-to-btm {
    right: -27px;
  }
  .main_header.is-sticky {
    top: 56px;
  }
  .top-mail ul li:nth-child(2) {
    margin-top: 5px;
  }
  .main_header {
    top: 56px;
  }
  .banner-information {
    margin-top: 0em;
  }
  .home-banner {
    height: auto;
    padding-top: 7vh !important;
    padding-bottom: 7vh;
  }
}

@media (min-width: 425px) and (max-width: 700px) {
  .team-item-description {
    width: calc(200% + 20px);
  }
  .team-item:nth-of-type(2n + 2) .team-item-description {
    margin-left: calc(-100% - 15px);
  }
}

@media only screen and (max-width: 380px) {
  .team-item-brief p.team-item-title {
    font-size: 12px;
  }
  .team-item:nth-of-type(2n + 2) .team-item-description {
    margin-left: 0;
  }
  .team-item-description {
    width: calc(100%);
  }
  .team-item {
    width: calc(100%);
  }
  .team-item-description-main {
    width: calc(100%);
  }
  .team-item-main {
    width: calc(100%);
  }

  .team-item-description {
    margin: 0 0 1em 0;
  }
  .main_header {
    top: 0;
  }
}

@media only screen and (max-width: 375px) {
  .top-to-btm {
    right: -28px;
  }
}
